/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import {Link} from 'gatsby';
import {useStaticQuery, graphql} from 'gatsby';
import {Icon} from 'rsuite';

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: {regex: "/profile-pic.jpg/"}) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `);

  const {author, social} = data.site.siteMetadata;
  return (
    <div className="blog-footer">
      <div>
        Made by
        <Link to="/about">{author.name}</Link>
      </div>
      <div className="w__icons">
        <a a href="https://github.com/reo777" target="_blank" rel="noreferrer">
          <Icon icon="github" size="2x" />
        </a>
        <a
          a
          href="https://twitter.com/NaReto1125_"
          target="_blank"
          rel="noreferrer"
        >
          <Icon icon="twitter" size="2x" />
        </a>
      </div>
    </div>
  );
};

export default Bio;
